<template>
    <div class="inner-wrapper" v-if="data" :class="data.playlistType">
        <slot name="header">
            <h2 class="column-title" v-if="data.displayTitle && data.displayTitle !== '' && data.displayTitle !== '#'">{{
                data.displayTitle }}</h2>
        </slot>
        <div class="playlist-wrapper">

            <ContentSliderBar :contentType="contentType" :data="playlistItems"
                v-if="data.playlistType && data.playlistType == 'slider'" />

            <ContentGridCollection :data="playlistItems" :contentType="contentType" :overviewItemType="overviewItemType"
                v-if="data.playlistType && data.playlistType == 'overview'" />

            <!-- Show if is mobile overview -->
            <!-- <ContentSliderBar :data="playlistItems" v-if="data.playlistType && data.playlistType == 'overview'" /> -->

        </div>
        <slot name="footer">
            <div class="btn-wrapper" v-if="data.buttonUrl">
                <a :href="data.buttonUrl">{{ data.buttonLabel }}</a>
            </div>
        </slot>
    </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

// @import component
const ContentSliderBar = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Content/ContentSliderBar"));
const ContentGridCollection = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Content/ContentGridCollection"));

export default {
    name: "PlayList",
    components: {
        ContentSliderBar,
        ContentGridCollection,
    },
    inject: ["isAuthenticated"],
    data() {
        return {
            //data: null,
            playlistItems: [],
            showPlaylist: false,
            overviewItemType: null,
            //playlistContentType: null,
        };
    },
    props: ["data", "contentType"],
    created() {
        this.expandedInit();

    },
    mounted() { },
    methods: {
        expandedInit() {
            if (this.data) {

                if (this.data.playlistType) {
                    this.overviewItemType = this.data.playlistType;
                }

                if (this.data.relatedContentsExpanded && this.data.relatedContentsExpanded.length > 0) {

                    let adjustedArray = [];

                    this.data.relatedContentsExpanded.forEach(function (item) {

                        if (item.entityType === "VideoContent") {
                            adjustedArray.push(item.videoContent);
                        } else if (item.entityType === "TemplateContent") {
                            adjustedArray.push(item.templateContent);
                        }

                    });

                    this.playlistItems = adjustedArray;

                    this.showPlaylist = true;

                }

            }
        }
    },
};
</script>

<!-- <style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style> -->
